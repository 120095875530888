
.listContainer {
    overflow: visible;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

.genderContainer {
    padding-left: 12px;
    padding-right: 12px;
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100% - 70px);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}

.pageInfo {
    padding-left: 12px;
    padding-right: 12px;
}
.title {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 4px;
    font-weight: 400;
    margin: 0;
}

.description {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 12px;
    font-weight: 400;
    margin: 0;
}

.weight {
    font-family: "Nunito Sans", sans-serif;
    color: var(--dark-20);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
}

.speedButtonsContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0 8px;
}
.mode {
    text-align: center;
    padding-top: 4px;
    color: var(--dark-20);
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    line-height: 28px;
}

.modeDescription {
    text-align: center;
    color: var(--gray-70);
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 12px;
}

.info {
    padding: 8px 12px 20px;
}

.text  {
    padding-top: 12px;
    padding-bottom: 8px;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
}
