.container {
    padding: 12px;
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--gray-90);
    margin-bottom: 12px;
}
.title {
    font-size: 16px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
}
.titleContainer {
    display: flex;
    align-items: center;
}
.bmiValue {
    font-size: 32px;
    line-height: 40px;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
}
.bmiName {
    padding-left: 8px;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
}
.description {
    font-size: 14px;
    line-height: 20px;
    font-family: "Nunito Sans", sans-serif;
    color: var(--gray-60);
}
.description a {
    font-weight: 600;
    color: var(--gray-60);
}

.summaryContainer {
    display: flex;
    padding-top: 12px;
}
.summaryInfo {
    flex: 1;
}
.summaryInfoElement {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    padding-right: 12px;
}
.summaryInfoElement:last-child {
    padding-bottom: 0;
}
.iconContainer {
    width: 32px;
    height: 32px;
}
.textContainer {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
}
.summaryTitle {
    color: var(--gray-60);
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.summaryValue {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text);
}

.goalsList {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    width: 120px;
    height: 228px;
    object-fit: contain;
}
