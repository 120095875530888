
.listContainer {
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: visible;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

.genderContainer {
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

@media screen and (max-width: 540px) {
    .container {
        /*height: calc(100% - 48px);*/
    }
}
.skill {
    margin-bottom: 8px;
    border: 1px solid var(--gray-90);
    border-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}
.skill .imageContainer {
    width: 82px;
    height: 82px;
}
.skill .imageContainer .image {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.infoContainer {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.skill .name {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}
.skill .name.selected {
    color: var(--dark-40);
}
.skill .shortDesc {
    font-family: "Nunito Sans", sans-serif;
    color: var(--gray);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
.skill .description {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

.selectedIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--dark-20);
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100% - 70px);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}
