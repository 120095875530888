.container {
    margin-top: 8px;
    padding: 40px 80px 40px 80px;
    border-radius: 12px;
    background-color: var(--gray-95);
}
@media screen and (max-width: 768px){
    .container {
        padding: 40px;
    }
}
@media screen and (max-width: 540px){
    .container {
        padding: 20px;
    }
}
.titleBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
}
.titleInfo {
    flex: 1;
    text-align: center;
    padding: 0 25px;
}
.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
}
.subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

.rating {
    padding: 22px;
    gap: 40px;
    border-radius: 12px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.starsBlock {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.stars {

}
.starsInfo {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: var(--new-dark-20);
}
.starsInfoCurrent {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}
