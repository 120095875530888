
.listContainer {
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

.genderContainer {
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

@media screen and (max-width: 540px) {
    .container {
        /*height: calc(100% - 48px);*/
    }
}

.activityItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-color: var(--gray-scale-10);
    border-radius: 12px;
    margin-bottom: 8px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    padding: 22px 26px 22px 12px;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}

.activityItemContainer.selected {
    background-color: var(--normal-80);
    border-color: var(--dark-20);
}

.activityInfo {
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.activityItem {
    display: flex;
    flex: 1;
    max-width: 350px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.activityItem .iconContainer {
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center
}

.activityItem .title {
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
}

.activityItem .description {
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    color: var(--gray);
    font-size: 16px;
    line-height: 24px;
}
