.container {
    padding-left: 12px;
    padding-right: 12px;
}
.navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4px;
}
.title {
    display: flex;
    align-items: center;
}
.title .text {
    font-size: 24px;
    line-height: 32px;
    font-family: "Nunito Sans", sans-serif;
}
.description {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
}
.done {
    cursor: pointer;
    color: var(--dark-20);
}
.backButton {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}
