.goalItemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-width: 1px;
    border-style: solid;
    border-color: var(--gray-scale-10);
    border-radius: 12px;
    margin-bottom: 8px;
    padding: 17px 20px 17px 80px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.goalItemContainer.selected {
    border-color: var(--dark-20);
}
.goalItemContainer .checkedContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--dark-20);
}
.goalItemContainer .goalItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.goalItemContainer .goalItem .iconContainer {
    margin-right: 12px;
    width: 32px;
    height: 32px;
}
.goalItemContainer .goalItem .itemText {
    font-size: 16px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
}
