.title {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 0 12px 24px;
}
.driver {
    border-top: 1px solid #E6E7E6
}
.checkoutContainer {
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.text {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-wrap: balance;
}
.label {
    font-weight: 700;
    padding-bottom: 8px;
}
.infoRowContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.infoRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px
}
.checkoutContainer .infoRow {
    padding-bottom: 8px;
}
.checkoutContainer .infoRow:last-child {
    padding-bottom: 0;
}
.totalContainer {
    padding: 24px 12px;
    color: var(--dark-40);
    text-transform: uppercase;
}
.totalContainer .infoRow {
    font-weight: 700;
}
.paymentContainer {
    padding: 24px 0;
}

.paymentLabel {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    padding: 0 12px 8px;
}
.paymentBlock {
    padding: 44px;
    border: 1px solid var(--gray-scale-10);
    border-radius: 12px;
}
.termsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding: 12px 12px 44px;
}
.termsContainer a {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    color: var(--base-color);
}
.termsContainer a:visited {
    color: var(--base-color);
}
.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: visible;
}

.contentData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

@media screen and (max-width: 540px) {
    .title {
        padding-top: 44px;
    }
    .paymentBlock {
        padding: 20px;
    }
    .cont {
        padding: 0 24px;
    }
    .text {
        text-wrap: wrap;
    }
    .infoRow .titleForRow {
        width: 50%;
    }
}
