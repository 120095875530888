.container {
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.containerName {
    width: 68px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    text-align: center;
}
.name {
    font-family: "Nunito Sans", sans-serif;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
}
.value {
    font-family: "Nunito Sans", sans-serif;
    padding-left: 8px;
}
