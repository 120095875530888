.row {
    display: block;
    padding-top: 80px;
    position: relative;
    height: 100%;
    max-height: 100vh;
}
.webview .container {
    min-height: calc(100dvh - 50px);
}
.container {
    max-width: 1408px;
    margin: auto;
    padding: 0 25px;
    /*position: relative;*/
    min-height: 100dvh;
    overflow: visible;
    background-color: white;
}
.container > div {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.backButton {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}

.onboardingContainer {
    max-width: 540px;
    margin: 0 auto;
    flex: 1;
    /*max-height: calc(100% - 100px);*/
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: var(--white);
    /*padding-right: 12px;*/
    /*padding-left: 12px;*/
}

.logo {
    position: absolute;
    top: 80px;
    left: 0;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}
.logo:focus {
    outline: none;
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .container {
        max-width: 1024px;
    }
}

@media screen and (max-width: 1280px) {
    .logo {
        top: 80px;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        margin: 0;
        max-width: 100%;
    }
    .row {
        padding: 48px 48px 0 48px;
    }
    .logo {
        top: 48px;
    }
}

@media screen and (max-height: 769px) {
    .logo {
        top: 48px;
    }

    .row {
        padding: 48px 48px 0 48px;
    }
    .onboardingContainer {
        padding: 0 12px;
        /*max-height: calc(100% - 68px);*/
        /*height: 100vh;*/
    }
}
@media screen and (max-width: 769px) {
    .container {
        /*padding: 48px;*/
        max-width: 100%;
    }
    .onboardingContainer {
        max-width: 80%;
    }
}

@media screen and (max-width: 540px) {
    .onboardingContainer.payment {
        /*max-height: none;*/
        /*overflow: auto;*/
    }
    .navBar {
        /*padding-top: 25px;*/
    }
    .container {
        display: block;
        padding: 0;
        margin: 0;
        max-height: none;

    }
    .row {
        padding: 0;
        display: block;
        /*flex: 1;*/
    }
    .onboardingContainer {
        box-sizing: border-box;
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 475px;
        height: 100%;
        margin: 0 auto;
        max-height: none;
        padding: 12px 12px 0;
        /*padding: 0 24px;*/
        /*display: flex;*/
        /*flex: 1;*/
        /*flex-direction: column;*/
        /*max-height: calc(100% - 20px);*/
    }

    .logo {
        display: none;
    }
    .welcome {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        width: 100vw;
        max-width: 100%;
    }
}
