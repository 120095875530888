
.listContainer {
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: column;
    flex: auto;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: none;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    display: flex;
    overflow: visible;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

.genderContainer {
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

@media screen and (max-width: 540px) {
    .container {
        /*height: calc(100% - 48px);*/
    }
}
.inputField {
    background-color: var(--normal-80);
    width: 100%;
    display: flex;
    border-radius: 12px;
    padding: 12px;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    border: 1px solid transparent;
}

.inputField::placeholder {
    color: var(--dark-40);
}

.error {
    /*padding-top: 10px;*/
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    color: var(--error-primary);
    font-size: 16px;
    line-height: 24px;
}

.inputField:focus {
    outline: none;
    border-color: var(--dark-20);
}

.info {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}
.info a {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}
