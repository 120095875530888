.Legend {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
}
.Legend .text {
    color: var(--text);
}
.Legend .info {
    width: 123px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.Legend .info .value {
    width: 64px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    font-weight: 400;
}
