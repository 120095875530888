.listContainer {
    /*padding: 0 20px 20px;*/
    padding-bottom: 32px;
}
.listElement {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 8px;
}
.listElementText {
    padding-left: 8px;
}
.formImages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    /*padding-bottom: 20px;*/
}
.formImages img {
    width: auto;
    height: auto;
    max-height: 40px;
}
.formImages > div {
    height: 40px;
}
.checkIconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 768px) {
    .listElementText {
        padding-right: 40px;
    }
}
@media screen and (max-width: 540px) {
    .listElementText {
        padding-right: 12px;
    }
}
.text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    padding-bottom: 12px;
}
.container {
    padding: 0 20px 20px;
}
.checkContainer {
    padding-top: 12px;
    padding-bottom: 32px;
    cursor: pointer;
    display: flex;
}
.checkText {
    padding-left: 12px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
