.buttonContainer {
    /*cursor: pointer;*/
    background-color: var(--pink);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: 20px 0;
}
.buttonContainer svg {
    padding-right: 8px;
}
.buttonText {
    color: var(--white);
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}
.clock {
    position: relative;
    padding: 10px;
    background: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
}
.fixedClock {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    height: 72px;
    justify-content: center;
    background: #6A9953CC;
    font-family: "Nunito Sans", sans-serif;
    color: var(--white);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

@media screen and (max-width: 540px){
    .fixedClock {
        height: 64px;
        font-size: 18px;
        line-height: 24px;
    }
}
