.stepInfoText {
    margin-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
}

.stepInfoText2 {
    padding: 0;
}

.title {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}
.description {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.title2 {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
}
.description2 {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0 4px;
}
