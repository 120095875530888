.smilesElement {
    display: flex;
    align-items: center;
    gap: 8px
}

.rowContainer {
    flex: 1;
    align-items: start;
}

.progress {
    border-radius: 20px;
    background-color: var(--gray-95);
    position: relative;
    overflow: hidden;
}

.title {
    padding: 4px 16px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--text);
    z-index: 10;
    position: relative;
}
.description {
    padding-left: 56px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--gray-60);
}
.progressElement {
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 20px;
    transition: all 1s;
}
