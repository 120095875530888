.titleContainer {
    padding: 0 12px;
    max-width: 400px;
    margin: 0 auto;
}
.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
}
