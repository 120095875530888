:root {
  --dark: #B0FF8B;
  --dark-20: #79C257;
  --new-dark-20: #79C257;
  --dark-40: #6A9953;
  --dark-60: #466638;
  --dark-80: #23331C;
  --normal-20: #C0FFA2;
  --normal-40: #D0FFB9;
  --normal-60: #DFFFD1;
  --normal-80: #F1FAED;
  --new-normal-80: #F1FAED;
  --text: #090D07;
  --text-white: #FFFFFF;
  --white: #FFFFFF;
  --gray-scale-10: #e6e7e6;
  --disableButtonBg: #bbe0a9;
  --error-primary: #FFA2A2;
  --error-secondary: #FFF6F6;
  --gray: #9D9E9C;
  --gray-90: #E6E7E6;
  --gray-60: #9D9E9C;
  --gray-70: #B5B6B5;
  --new-gray-70: #CBCCCB;
  --gray-95: #F3F3F3;
  --new-gray-95: #F5F5F5;
  --white-transparent: #FFFFFFCC;
  --pink: #F25570;
  --textGr: #666666;
  --green: #00B67A;
  --link: #8B90FF;
  --tooltip-text-color: #090D07;
  --tooltip-background-color: #D0FFB9;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 10px;
}

img {
  pointer-events: none;
  /*width: 100%;*/
  /*height: auto;*/
}

.overflowContainer {
  overflow: auto;
  transition: all 300ms ease-in-out;
}

body {
  position: relative;
}

.Tooltip-Tip {
  position: absolute;
  left: 0;
  transform: translateX(-80%);
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  width: 180px;
  bottom: calc(100% + 10px);
  padding: 8px;
  border-radius: 12px;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  right: 17px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  /*top: calc(var(--tooltip-margin) * -1);*/
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

@media screen and (max-width: 540px){
  .hideScrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .hideScrollbar::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}
