.page {
    height: 100dvh;
    width: 100dvw;
    overflow: hidden;
    display: flex;
}
.container {
    max-width: 1450px;
    /*position: relative;*/
    margin: 0 auto;
    padding: 80px 25px;
    flex: 1;
    display: flex;
}

.row {
    margin: auto;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}
.col {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
}
.rightCol {
    display: flex;
    flex-direction: column;
    flex: 1
}
.imageContainer {
    height: 45%;
    display: flex;
}

.styledText {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 48px;
    line-height: 57px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.varOne {
    font-weight: 600;
    color: var(--dark-20);
}
.varTwo {
    background-color: var(--dark-20);
    color: var(--white);
    border-radius: 12px;
    height: 48px;
    padding: 0 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.underline {
    position: relative;
}
.underline:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--dark-20);
}

.nextButton {
    margin-top: 60px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    height: 48px;
    background-color: var(--dark-20);
    color: var(--text-white);
    text-transform: full-size-kana;
    margin-bottom: 40px;
    font-family: "Nunito Sans", sans-serif;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

}

.logo {
    width: 112px;
    height: 112px;
}
.images {
    padding: 20px 0;
}
.imgs {
    display: flex;
    align-items: center;
    justify-content: center;
}
.images:before {
    content: '';
    width: 100vw;
    height: 100vw;
    top: -50%;
    border-radius: 50%;
    z-index: 1;
    left: -150px;
    position: absolute;
    background-color: var(--normal-60);
}

.relative {
    position: relative;
}

.phones {
    width: 100%;
    height: auto;
    position: relative;
    left: 20px;
    z-index: 2;
}
.saveTime {
    position: absolute;
    left: -5px;
    top: 594px;
}
.eat {
    position: absolute;
    left: -72px;
    top: 269px;
}
.loseWeight {
    position: absolute;
    right: 0;
    top: 85px;
}
.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    box-shadow: 2px 2px 16px 0 #B5B6B533;
    border-radius: 200px;
    padding-right: 24px;
    background-color: var(--white);
    z-index: 2;
}
.iconContainer span {
    padding-left: 12px;
    font-size: 18px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
}
.iconContainer img {
    width: 56px;
    height: 56px;
}

.mobileText {
    display: none;
}

@media screen and (max-width: 2560px) {
    .images:before {
        top: -80%;
    }
}

@media screen and (max-width: 1920px) {
    .container {

    }
    .images:before {
        width: 1428px;
        height: 1428px;
        top: -30%;
    }
}

@media screen and (max-width: 1280px) {
    .container {
        padding: 48px 25px;
    }
    .imgs {
        display: block;
    }
    .images {
        padding: 0;
        height: 100%;
    }
    .images:before {
        left: -100px;
    }
    .phones {
        height: 100%;
    }
    .saveTime {
        left: 0;
        top: 370px
    }
    .eat {
        left: -30px;
        top: 160px;
    }
    .loseWeight {
        right: 0;
        top: 35px;
    }
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .logo {
        width: 92px;
        height: 92px;
        margin-bottom: 40px;
    }
    .images:before {
        top: -30%;
    }
    .saveTime {
        left: -15px;
        top: 480px;
    }
    .eat {
        left: -40px;
        top: 210px;
    }
    .loseWeight {
        right: 0;
        top: 35px;
    }
}

@media screen and (max-height: 1024px) and (orientation: landscape) {
    .eat {
        left: -50px;
        top: 280px;
    }
    .saveTime {
        left: 20px;
        top: 590px;

    }
    .loseWeight {
        right: 10px;
        top: 80px;
    }  top: 50px;
}

@media screen and (max-height: 820px) and (orientation: landscape) {
    .eat {
        left: -50px;
        top: 210px;
    }
    .saveTime {
        left: 0px;
        top: 490px;

    }
    .loseWeight {
        right: 10px;
        top: 50px;
    }
}


@media screen and (max-width: 1024px) {
    .page {
        overflow: hidden;
        height: auto;
        width: 100%;
        min-height: 100vh;
        min-width: 100vw;
    }
    .container {
        padding: 48px 48px 0;
    }
    .nextButton {
        position: absolute;
        bottom: 48px;
        width: calc(100% - 72px);
        z-index: 55;
        margin: 0;
    }
    .row {
        flex-direction: column;
        margin: 0;
    }
    .phones {
        width: 440px;
        height: 560px;
    }
    .logo {
        width: 92px;
        height: 92px;
        margin-bottom: 40px;
    }
    .images:before {
        top: 150px;
        left: -500px;
    }
    .imageContainer {
        height: 35%;
    }
    .iconContainer {
        padding-right: 20px;
    }
    .iconContainer span {
        padding-left: 8px;
        font-size: 14px;
        line-height: 18px;
    }
    .iconContainer img {
        width: 42px;
        height: 42px;
    }
}

@media screen and (max-height: 768px) and (orientation: landscape) {
    .phones {
        width: 440px;
        height: 560px;
    }
    .eat {
        left: -50px;
        top: 150px;
    }
    .saveTime {
        left: 0;
        top: 380px;
    }
    .loseWeight {
        right: 10px;
        top: 50px;
    }
    .images:before {
        top: -65%;
    }
    .iconContainer {
        padding-right: 20px;
    }
    .iconContainer span {
        padding-left: 8px;
        font-size: 14px;
        line-height: 18px;
    }
    .iconContainer img {
        width: 42px;
        height: 42px;
    }
}

@media screen and (max-height: 768px) and (max-width: 1024px) {
    .images:before {
        top: 150px;
    }
}

@media screen and (max-width: 1024px) and (min-width: 769px){
    .textContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 768px){
    .imageContainer {
        height: auto;
    }
}

@media screen and (max-width: 540px) {
    .container {
        padding: 24px 24px 0;
    }
    .row {
        height: auto;
        margin: 0;
    }
    .logo {
        width: 72px;
        height: 72px;
        margin-bottom: 24px;
    }
    .nextButton {
        bottom: 12px;
        width: calc(100% - 24px);
        left: 12px;
    }
    .images {
    }
    .page {
        overflow: hidden;
        height: auto;
        min-height: 100vh;
    }
    .phones {
        width: 327px;
        height: 398px;
        left: 0;
    }
    .mobileText {
        display: block;
    }
    .text {
        display: none;
    }
    .eat {
        left: 0;
        top: 110px;
    }
    .saveTime {
        left: 0;
        top: 265px;
    }
    .loseWeight {
        right: 10px;
        top: 30px;
    }
    .iconContainer {
        padding-right: 15px;
    }
    .iconContainer span {
        padding-left: 6px;
        font-size: 12px;
        line-height: 16px;
    }
    .iconContainer img {
        width: 32px;
        height: 32px;
    }
    .imgs {
        padding-top: 28px;
    }
}


