.imageContainer {
    border-radius: 0 0 18px 18px;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    overflow: hidden;
    display: flex;
    margin-bottom: 12px;
    position: relative;
    max-height: 292px;
}
.imageContainer:after {
    content: '';
    width: 100%;
    height: 68px;
    bottom: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(rgba(255, 255, 255, 0), #FFFFFF);
}
.image {
    width: 100%;
    height: auto;
    /*object-position: right;*/
    object-fit: cover;
}
.buttonContainer {
    padding-left: 12px;
    padding-right: 12px;
}
.iconContainer {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
}
.text {
    position: absolute;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
.titleOne {
    top: 38px;
    left: 68px;
}
.titleTwo {
    top: 44px;
    right: 77px;
}
.titleThree {
    top: 88px;
    left: 160px;
}
.titleFore {
    bottom: 68px;
    left: 78px;
}
.titleFive {
    bottom: 48px;
    right: 78px;
}
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100% - 48px);
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100%);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}
