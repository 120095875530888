.container {
    border-bottom: 1px solid var(--gray-95);
    cursor: pointer;
}
.ingredient {

    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

}
.ingredient .name {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    max-width: calc(100vw - 100px);
    overflow: hidden;
}
