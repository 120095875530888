.page {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
}
.container {
    max-width: 1450px;

    margin: 0 auto;
    padding: 80px 25px;
    flex: 1;
    display: flex;
}

.row {
    margin: auto;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}
.col {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
}
.rightCol {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.textInfo {
    max-width: 550px;
}
.imageContainer {
    height: 35%;
    display: flex;
}

.title {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.description {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.styledText {
    padding: 60px 0;
    font-family: "Nunito Sans", sans-serif;
    color: var(--dark-20);
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    margin: 0;
}
.styledDownloadText {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    padding-bottom: 16px;
}
.varOne {
    font-weight: 600;
    color: var(--dark-20);
}
.varTwo {
    background-color: var(--dark-20);
    color: var(--white);
    border-radius: 12px;
    height: 48px;
    padding: 0 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.underline {
    position: relative;
}
.underline:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--dark-20);
}

.nextButton {
    margin-top: 60px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    height: 48px;
    background-color: var(--dark-20);
    color: var(--text-white);
    text-transform: full-size-kana;
    margin-bottom: 40px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
}

.logo {
    width: 112px;
    height: 112px;
}
.images {
    padding: 20px 0;
}
.imgs {
    display: flex;
    align-items: center;
    justify-content: center;
}
.images:before {
    content: '';
    width: 100vw;
    height: 100vw;
    top: -50%;
    border-radius: 50%;
    z-index: -1;
    left: -150px;
    position: absolute;
    background-color: var(--normal-60);
}

.download {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.download .appstore {
    display: flex;
    flex-direction: column;
}

.relative {
    position: relative;
}

.phones {
    width: 100%;
    height: auto;
    position: relative;
    left: 20px;
}
.saveTime {
    position: absolute;
    left: -5px;
    top: 594px;
}
.eat {
    position: absolute;
    left: -72px;
    top: 269px;
}
.loseWeight {
    position: absolute;
    right: 0;
    top: 85px;
}

.mobileText {
    display: none;
}

@media screen and (max-width: 2560px) {
    .images:before {
        top: -80%;
    }
}

@media screen and (max-width: 1920px) {
    .container {

    }
    .images:before {
        width: 1428px;
        height: 1428px;
        top: -30%;
    }
}

@media screen and (max-width: 1280px) {
    .container {
        padding: 48px 25px;
    }
    .imgs {
        display: block;
    }
    .images {
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .images:before {
        left: -100px;
    }
    .phones {
        height: auto;
    }
    .saveTime {
        left: -25px;
        top: 394px;
        height: auto;
        width: 175px;
    }
    .eat {
        left: -60px;
        top: 160px;
        height: auto;
        width: 175px;
    }
    .loseWeight {
        right: 0;
        top: 55px;
        height: auto;
        width: 175px;
    }
}

@media screen and (max-width: 1366px) and (orientation: landscape) {
    .imageContainer {
        height: 25%;
    }
    .logo {
        width: 92px;
        height: 92px;
        margin-bottom: 40px;
    }
    .images:before {
        top: -30%;
        left: -10px;
    }
    .saveTime {
        left: -5px;
        top: 550px;
    }
    .eat {
        left: -72px;
        top: 250px;
    }
    .loseWeight {
        right: 0;
        top: 85px;
    }
}

@media screen and (max-width: 1024px) {
    .page {
        overflow: hidden;
        height: auto;
        width: 100%;
        min-height: 100vh;
    }
    .styledText {
        padding: 24px 70px 24px 0;
    }
    .rightCol, .textInfo {
        max-width: none;
    }
    .container {
        padding: 48px 48px 0;
    }
    .row {
        flex-direction: column;
        margin: 0;
    }
    .phones {
        /*width: 440px;*/
        /*height: 520px;*/
        left: 0;
    }
    .logo {
        width: 92px;
        height: 92px;
        margin-bottom: 40px;
    }
    .images:before {
        top: 150px;
        left: -440px;
    }
    .imageContainer {
        height: 35%;
    }
    .nextButton {
        margin-bottom: 20px;
        margin-top: 30px;
    }
}

@media screen and (max-height: 769px) and (orientation: landscape) {
    .logo {

    }
    .styledText {
        padding: 40px 0;
    }
    .phones {
        width: 400px;
        height: auto;
    }
    .eat {
        left: -50px;
        top: 150px;
    }
    .saveTime {
        left: 0;
        top: 380px;
    }
    .loseWeight {
        right: 10px;
        top: 50px;
    }
    .eat, .saveTime, .loseWeight {
        width: 165px;
        height: auto;
    }
    .images:before {
        top: -35%;
        left: -50px;
    }
}

@media screen and (max-width: 450px) {
    .imgs {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .container {
        padding: 24px 24px 0;
    }
    .row {
        height: auto;
        margin: 0;
    }
    .logo {
        width: 72px;
        height: 72px;
        margin-bottom: 24px;
    }
    .nextButton {
        width: 100%;
        margin-bottom: 24px;
        margin-top: 32px;
    }
    .images {
    }
    .page {
        overflow: hidden;
        height: auto;
        min-height: 100vh;
    }
    .phones {
        width: 327px;
        /*height: 398px;*/
        left: 0;
    }
    .images:before {
        left: -520px;
    }
    .styledText {
        padding-right: 0;
    }
}


