.inputContainer {
    margin-bottom: 15px;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

.listContainer {
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100% - 80px - 48px);
    overflow: hidden;
}
.containerData {
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    overflow: hidden;

}
.listData {
    flex: 1;
    overflow: auto;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 20px;
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100% - 48px);
    }
}
