.container {
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-radius: 12px;
    margin-bottom: 8px;
    cursor: pointer;
}
.mealContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.image {
    border-radius: 12px;
    width: 80px;
    height: 80px;
}
.text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    padding-left: 24px;
}

.iconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--dark-20);
}
