.container {
    padding-bottom: 8px;
}
.container > div {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.row {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.header {
    display: flex;
    height: 48px;
    position: relative;
    flex: 1;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    border-width: 1px;
    border-style: solid;
}
.iconContainer {
    padding: 12px;
    transition: transform 0.5s ease;
    transform: rotate(0deg);
}
.textContainer {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
}
.textContainer .text {
    font-size: 18px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
}
.textContainer .subText {
    font-size: 12px;
    line-height: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    color: var(--gray);
}
.disableProductButton {
    display: flex;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid transparent;
    position: relative;
    z-index: 4;
}
.dot {
    display: flex;
    width: 5px;
    height: 5px;
    position: absolute;
    right: 12px;
    top: 12px;
    border-radius: 6px;
    z-index: 5;
}
.image {
    width: 48px;
    height: 48px;
    border-radius: 12px;
}
.bodyContainer {
    /*display: flex;*/
    flex-direction: column;
    border-radius: 12px;
    background-color: #ffffff;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
}
.body {
    /*position: absolute;*/
    width: 100%;
    transition: all 0.3s ease;
    opacity: 0;
    z-index: -1;
}
.open {
    opacity: 1;
    z-index: 5;
}

.rotate {
    transform: rotate(180deg);
}
