.container {
    display: flex;
    padding-bottom: 8px;
}
.icon {
    width: 16px;
}
.title {
    padding-left: 8px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--text);
}
