.wrapper {

}

.container {
    display: flex;
    background-color: pink;
}

.barCompleted {
    display: flex;
    background-color: lightblue;
}

.label {
    font-size: 20px;
    color: green;
}

.fade-enter{
    opacity: 0;
}
.fade-exit{
    opacity: 1;
}
.fade-enter-active{
    opacity: 1;
}
.fade-exit-active{
    opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
    transition: opacity 500ms;
}
.slide-enter {
    transform: translateX(100%);
}

.slide-enter-active {
    transform: translateX(0%);
    transition: transform 300ms;
}

.slide-exit {
    transform: translateX(0%);
}

.slide-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms;
}

.slideb-enter {
    transform: translateY(100%);
    opacity: 0;
}

.slideb-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 300ms linear, opacity 300ms;
}

.slideb-exit {
    transform: translateY(0);
    opacity: 1;
}

.slideb-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: transform firems linear, opacity 300ms;
}
