.container {
    overflow: auto;
    flex: 1;
    display: flex;
    height: 100dvh;
}
.container a {
    color: var(--link);
}
.container a:hover, .container a:focus, .container a:visited {
    color: var(--link);
}
.content {
    max-width: 1120px;
    margin: auto;
    padding: 112px 0;
}

.pageContent {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.goalsList {
    font-family: "Nunito Sans", sans-serif;
    padding-bottom: 0;
    padding-top: 20px;
}
.goalsList li::marker {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin: 0;
    font-weight: 700;
}
.goalsList li, .goalsList li p {
    padding-bottom: 12px;
}
.goalsList li:last-child, .goalsList li:last-child p {
    padding-bottom: 0;
}
.blockContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.dietsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.dietContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.adjustingContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.adjustingList {
    padding-top: 0;
    padding-bottom: 0;
}
.adjustingList li {
    padding-bottom: 12px;
}
.adjustingList li:last-child {
    padding-bottom: 0;
}
.adjustingList li::marker {
    font-size: 24px;
}
.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    padding: 10px 0;
    margin: 0;
}
.subTitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    margin: 0;
}
.titleContainer {
    padding-bottom: 60px;
}
.text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin: 0;
}
.boldText {
    font-weight: 700;
}

@media screen and (max-width: 1024px) {
    .content {
        padding: 48px;
    }
    .titleContainer {
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 540px) {
    .content {
        padding: 40px 24px;
    }
}
