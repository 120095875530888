.container {
    /*display: flex;*/
}
.subCategoryItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 8px;
    /*padding-left: 12px;*/
}
.header {
    display: flex;
    flex: 1;
    height: 48px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    outline: none;
}
.iconContainer {
    padding: 12px;
    transition: transform 0.5s ease;
    transform: rotate(0deg);
}
.textContainer {
    display: flex;
    padding-left: 8px;
}
.textContainer .text {
    font-size: 18px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
}
.disableProductButton {
    display: flex;
    padding: 12px;
    border-radius: 12px;
    position: relative;
    z-index: 4;
}
.dot {
    display: flex;
    width: 5px;
    height: 5px;
    position: absolute;
    right: 12px;
    top: 12px;
    border-radius: 6px;
    z-index: 5;
}
.bodyContainer {
    background-color: #ffffff;
    overflow: hidden;
}
.body {
    width: 100%;
    transition: all 0.3s ease;
    opacity: 0;
    /*position: absolute;*/
    /*display: none;*/
}
.open {
    opacity: 1;
    display: block;
}
.rotate {
    transform: rotate(180deg);
}
