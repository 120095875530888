@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.container {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 24px;
}
.content {
    display: flex;
    align-content: center;
    align-items: center;
}

.content .text {
    font-size: 24px;
    line-height: 32px;
    padding-left: 12px;
}
