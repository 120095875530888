.textContainer {
    padding-left: 8px;
    display: flex;
    flex: 1
}
.text {
    font-size: 18px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
}
.fakeIcon {
    width: 48px;
    height: 48px;
}
.container {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 48px;
    flex: 1;
    cursor: pointer;
}
.iconContainer {
    padding: 12px;
    border-radius: 12px;
}
