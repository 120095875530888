.NumberFieldLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-color: var(--new-dark-20);
    background-color: var(--new-normal-80);
    border-width: 1px;
    border-style: solid;
    border-radius: 12px;
    padding: 12px 12px 8px 12px;
    width: auto;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}

.NumberFieldLabel.hasError {
    border-color: var(--error-primary);
    background-color: var(--error-secondary);
    position: relative;
}

.NumberField {
    margin: 0;
    padding: 0;
    border: 0;
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    background-color: var(--new-normal-80);
    color: var(--text);
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    caret-color: var(--new-dark-20);
    max-width: 100px;
    pointer-events: auto;
}

.NumberField.hasError {
    background-color: var(--error-secondary);
    caret-color: var(--text);
}

.NumberField::-webkit-inner-spin-button,
.NumberField::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.NumberField:focus {
    outline: none;
}

.label {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 14px;
    line-height: 20px;
}

.error {
    font-family: "Nunito Sans", sans-serif;
    color: var(--error-primary);
    font-size: 16px;
    line-height: 24px;
    min-height: 24px;
    text-align: center;
    padding-top: 5px;
}
