.container {
    padding-top: 16px;
    border-bottom: 1px solid var(--gray-95);
    margin-bottom: 12px;
}
.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    padding: 4px 12px;
}
.reviewList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 12px;
}
.loadMore {
    border: none;
    outline: none;
    background-color: var(--white);
    color: var(--new-dark-20);
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    width: 100%;
    height: 48px;
    cursor: pointer;
    margin-bottom: 12px;
}
