.container {
    padding: 20px;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid var(--gray-90);
}
.reviewInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}
.nameContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.name {
    padding-left: 12px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}
.initials {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ratingContainer {
    display: flex;
    align-items: center;
    gap: 2px;
}
.starContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green);
}
.starContainer svg path {
    fill: var(--white);
}
.textContainer {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
