.codeButton {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    background-color: transparent;
    border: none;
    width: 100%;
    color: var(--new-dark-20);
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 16px;
}
