
.listContainer {
    overflow: visible;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

.genderContainer {
    padding-left: 12px;
    padding-right: 12px;
}

@media screen and (max-width: 540px) {
    .container {
        /*height: calc(100% - 48px);*/
    }
}
.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 8px;
}
.itemInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.image {
    width: 80px;
    height: 80px;
    border-radius: 12px;
}
.name {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    padding-left: 12px;
}
.iconsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.icon {
    cursor: pointer;
    padding: 14px;
    width: 22px;
    height: 22px;
    outline: none;
    border: none;
    background-color: transparent;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
    /*position: relative;*/
    /*z-index: 1;*/
}
.icon:focus {
    outline: none;
    background-color: transparent;
}
.icon:hover {
    outline: none;
    background-color: transparent;
}
