.or {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--gray-60);
}
.fakeAppleButton {
    width: 100%;
    height: 52px;
    background: white;
    border: 1px solid var(--text);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: 600;
    line-height: 27.45px;
    /*letter-spacing: -1.223181962966919px;*/
    text-align: center;
}

.error {
    color: var(--error-primary);
    padding-top: 5px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
}
