.container {
    padding-left: 22px;
    padding-right: 22px;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    margin-bottom: 8px;
    flex: 1;
    border-radius: 12px;
    cursor: pointer;
}

.formatContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.iconContainer {
    width: 32px;
    height: 32px;
    margin-right: 15px;
}

.textContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 5px;
    color: var(--text);
}

.description {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--gray);
}
