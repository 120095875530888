.container {
    position: relative;
    margin-top: 12px;
}
.rowElement {
    padding-bottom: 25px;
    display: flex;
    height: 8px;
    border-radius: 12px;
}
.element {
    position: relative;
    opacity: 0.8;
    border: 0;
}
.elementTitle {
    position: absolute;
    bottom: -25px;
    font-size: 14px;
    line-height: 20px;
    font-family: "Nunito Sans", sans-serif;
}
.elementTitleEnd {
    right: 0;
}
.slider {
    position: absolute;
    top: 0;
    left: -8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    transition: all 1s;
}
