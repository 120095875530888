.container {
    margin: 0;
    padding: 4px 0 78px;
}

.container ul {
    margin: 0;
    position: relative;
    list-style-type: none;
    background-color: var(--gray-95);
    padding: 20px 20px 20px 20px;
    border-radius: 12px;
}

.container ul li {
    position: relative;
    padding-left: 16px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 8px;
    color: var(--text);
}
.container ul li a {
    color: var(--text);
}
.container ul li:last-child {
    padding-bottom: 0;
}
.container ul li:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 3px;
    position: absolute;
    top: 5px;
    left: 0;
    background: var(--gray-70);
}
