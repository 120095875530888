.infoContainer {
    display: flex;
    flex-direction: row;
}
.icon {
    padding-right: 8px;
}
.textContainer {
    padding-right: 12px;
    padding-bottom: 20px;
}
.text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.description {
    padding-top: 4px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--gray-60);
}
