.chip {
    display: flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 40px;
    padding: 4px 8px;
    cursor: pointer;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}
.chipContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.image {
    height: 24px;
    width: 24px;
    border-radius: 23px;
}
.text {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    max-width: calc(100vw - 100px);
    overflow: hidden;
    white-space: nowrap;
}
