.listContainer {
    /*overflow: auto;*/
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    /*overflow: auto;*/

}
.listData {
    flex: 1;
    overflow: auto;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 20px;
}

.container {
    padding-bottom: 80px;
}

@media screen and (max-width: 1024px) {
    .container {
        padding-bottom: 48px;
    }
}

@media screen and (max-height: 769px) {
    .container {
        padding-bottom: 48px;
    }
}
@media screen and (max-width: 768px) {
    .container {
        width: 440px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 540px) {
    .container {
        width: 100%;
    }
}
.titleBlock {
    text-align: center;
    padding-bottom: 8px;
}

.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 4px;
    font-weight: 700;
}

@media screen and (max-width: 540px) {
    .title {
        font-size: 24px;
        line-height: 32px;
    }
}

.subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}


.varOne {
    font-weight: 700;
    color: var(--dark-20);
    margin-right: 7px;
    margin-left: 7px;
}
.varTwo {
    margin: 0 7px;
    background-color: var(--normal-80);
    border: 1px solid var(--dark-20);
    border-radius: 4px;
    color: var(--text);
    height: 38px;
    width: 62px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.varTwo.normal {
    width: 46px;
    font-weight: 700;
    margin-right: 0;
}

.error {
    text-align: center;
    color: var(--error-primary);
    padding: 5px 12px;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: visible;
}

.contentData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

@media screen and (max-width: 540px) {
    .container {
        /*height: calc(100% - 48px);*/
    }
    .container {
        padding: 25px 0;
    }
    .containerData {
        /*overflow: visible;*/
    }
}
