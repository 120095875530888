.hidden {
    opacity: 1;
}
.element {
    width: 88px;
    height: 88px;
    /*transform: translate(0px, 1014px);*/
    transition: all ease-in-out 0.5s;
    opacity: 0;
    /*display: none;*/
}
.hiddenElement {
    opacity: 1;
    width: 88px;
    height: 88px;
}
.container {
    position: fixed;
    top: 0;
    z-index: 1000;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: var(--dark-20);
}
