.nextButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    height: 48px;
    background-color: var(--dark-20);
    color: var(--text-white);
    text-transform: full-size-kana;
    font-family: "Nunito Sans", sans-serif;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

    position: relative;
    /*top: -12px;*/
    margin-bottom: 12px;
    /*margin-left: 12px;*/
    /*margin-right: 12px;*/
}

.subButtonContainer {
    background-color: var(--text-white);
    color: var(--dark-20);
    border: 1px solid var(--dark-20);
    margin-bottom: 8px;
}
.buttonContainer {
    position: initial;
    width: 100%;
}
.buttonContainer.fixed {
    display: block;
    position: sticky;
    bottom: 0;
    z-index: 15;
    left: 0;
    /*padding-bottom: 12px;*/
    background-color: var(--white);
    /*margin-right: -12px;*/
    /*padding: 20px 12px 12px 0;*/
    /*bottom: 12px;*/
    /*left: 0;*/
}
.withGradient:before {
    content: '';
    width: 100%;
    height: 84px;
    position: absolute;
    top: -35px;
    left: 0;
    background: linear-gradient(0.13deg, #FFFFFF 57.21%, rgba(255, 255, 255, 0.6) 73.57%, rgba(255, 255, 255, 0.4) 85.14%, rgba(255, 255, 255, 0.02) 99.88%);
}

.nextButtonContainer.disabled {
    background-color: var(--new-gray-95);
    color: var(--new-gray-70);
    opacity: 1;
    cursor: not-allowed;
}
