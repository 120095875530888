.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #090D0720;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 660px;
    background-color: #ffff;
    border-radius: 10px;
    padding: 40px;
    position: relative;
}
@media screen and (max-width: 768px) {
    .modal {
        width: 70%;
    }
}
.titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.title {
    padding-top: 14px;
    padding-bottom: 4px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: var(--text);
    max-width: 450px;
}
.closeIcon {
    padding: 8px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
}

.closeBtn{
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    text-align: center;
    padding: 12px 24px 12px 24px;
    border-radius: 12px;
    border: 0;
    width: 100%;
    background-color: var(--dark-20);
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: var(--white);
}

.field {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.label {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding-left: 12px;
    padding-bottom: 4px;
}
.inputContainer {

}
.inputField {
    background-color: var(--normal-80);
    /*width: 100%;*/
    border-radius: 12px;
    padding: 12px;
    border: 1px solid transparent;
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    width: calc(100% - 20px);
    /*width: 90%;*/
}

.inputField::placeholder {
    color: var(--dark-40);
}

.error {
    padding-top: 10px;
    padding-left: 12px;
    padding-bottom: 10px;
    text-align: left;
    font-family: "Nunito Sans", sans-serif;
    color: var(--error-primary);
    font-size: 16px;
    line-height: 24px;
}

.inputField:focus {
    outline: none;
    border-color: var(--dark-20);
}
