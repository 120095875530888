.imageContainer {
    position: relative;
    /*margin-left: 12px;*/
    /*margin-right: 12px;*/
}
.backButton {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    cursor: pointer;
    background-color: var(--white-transparent);
    display: flex;
    align-items: center;
    justify-content: center;
}
.image {
    border-radius: 12px;
    width: 100%;
    height: auto;
    max-height: 460px;
}
.dietContainer {
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-top: 16px;
}
.dietName {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: var(--text);
    padding: 8px 12px 20px;
}
.dietDescription {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 24px;
}

.chartData {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    gap: 20px
}

.chartData .chart {
    width: 50%;
    display: flex;
    justify-content: end;
}

.chartData .chart > * {
    -webkit-tap-highlight-color: transparent;
}

.chartData .relative {
    position: relative;
}

.chartData .chartInfoPosition {
    position: absolute;
    top: 50px;
    left: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chartData .chart .calories {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 18px;
}
.chartData .chart .desc {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 14px;
}

.chartLegend {
    width: 50%;
    justify-content: center;
}

@media screen and (max-width: 540px) {
    .image {
        height: 320px;
    }
}


.listContainer {
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 20px;
}

.genderContainer {
    padding-left: 12px;
    padding-right: 12px;
}

@media screen and (max-width: 540px) {
    .container {
        /*height: calc(100% - 48px);*/
    }
    .backButton {
        top: 22px;
    }
    .imageContainer {
        padding-top: 12px;
    }
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100%);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}

