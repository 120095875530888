/*svg.value #group-1*/
.animated {
    transform-origin: center;
    transform-box: fill-box;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
}
#element-5 {
    animation-name: animateElem5;
}
#element-6 {
    animation-name: animateElem6;
}
#element-4 {
    animation-name: animateElem4;
}
#element-7 {
    animation-name: animateElem7;
}
#element-9 {
    animation-name: animateElem9;
}
#element-10 {
    animation-name: animateElem10;
}
#element-11 {
    animation-name: animateElem11;
}
#element-8 {
    animation-name: animateElem8;
}
#element-3 {
    animation-name: animateElem3;
}
#element-2 {
    animation-name: animateElem2;
}
#group-1 {
    animation-name: animateElem1;
}

@keyframes animateElem5 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(200px, 200px);
        opacity: 0;
    }
}

@keyframes animateElem6 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(50px, 200px);
        opacity: 0;
    }
}

@keyframes animateElem11 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(-50px, 200px);
        opacity: 0;
    }
}
@keyframes animateElem3 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(-150px, 200px);
        opacity: 0;
    }
}
@keyframes animateElem8 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(-150px, -200px);
        opacity: 0;
    }
}

@keyframes animateElem5 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(200px, 200px);
        opacity: 0;
    }
}

@keyframes animateElem1 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(-200px, -200px);
        opacity: 0;
    }
}

@keyframes animateElem4 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(-50px, -200px);
        opacity: 0;
    }
}
@keyframes animateElem7 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(0, -200px);
        opacity: 0;
    }
}

@keyframes animateElem2 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(50px, -200px);
        opacity: 0;
    }
}

@keyframes animateElem9 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(200px, -200px);
        opacity: 0;
    }
}

@keyframes animateElem10 {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(200px, -50px);
        opacity: 0;
    }
}
