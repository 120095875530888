.item {
    flex-basis: calc(50% - 6px);
    height: 265px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid transparent;
    position: relative;
    object-fit: contain;
    cursor: pointer;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}
.item .topGradient {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.2) 75%, rgba(0, 0, 0, 0.01) 100%);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 56px;
    justify-content: center;
    z-index: 2;
}
.selectedIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--dark-20);
}
.item .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(0.61deg, rgba(0, 0, 0, 0.7) 0.46%, rgba(0, 0, 0, 0.6) 25.25%, rgba(0, 0, 0, 0.4) 52.17%, rgba(0, 0, 0, 0.2) 75.53%, rgba(0, 0, 0, 0.01) 97.61%);
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 56px;
    justify-content: center;
}
.item .gradient .text {
    color: var(--white);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: "Nunito Sans", sans-serif;
}
.item .gradient .desc {
    color: var(--white);
    font-size: 12px;
    line-height: 16px;
    font-family: "Nunito Sans", sans-serif;
}
.item .img  {
    width: 100%;
    height: 265px;
}

@media screen and (max-width: 768px){
    .item, .item .img  {
        height: 265px;
    }
}

@media screen and (max-width: 540px){
    .item, .item .img  {
        height: calc((100vw / 2) - 24px - 6px);
    }
}

.listContainer {
    overflow: visible;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 25px;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: auto;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

.genderContainer {
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

@media screen and (max-width: 540px) {
    .container {
        /*height: calc(100% - 48px);*/
    }
}
