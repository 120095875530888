.container {
    position: relative;
    padding: 20px;
    background-color: var(--gray-95);
    border-radius: 12px;
    margin-top: 24px;
    /*max-height: 275px;*/
}
.title {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 12px;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
}
.textContainer {
    /*display: flex;*/
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    /*padding-bottom: 150px;*/
}
.image {
    float: right;
    margin: 0 0 10px 10px; /* Добавляем отступы, чтобы текст не прижимался вплотную к картинке */
    /*width: 200px; !* Задайте нужную ширину картинки *!*/
    height: auto;
}
.text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    /*padding: 20px;*/
    /*text-align: justify;*/
}

.imageContainer img {
    width: 100%;
    height: auto;
}
.imageContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px; /* Задайте нужную ширину картинки */
    height: auto;
}
