.listContainer {
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}

.title {
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 12px;
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100%);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}

