.chipList {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.dislikedList {
    min-height: 34px;
}

.defaultList {
    padding-top: 12px;
}

.defaultList .title {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 20px;
    line-height: 28px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
}

.searchButton {
    cursor: pointer;
    height: 48px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--normal-80);
    border: 1px solid var(--normal-80);
    border-radius: 12px;
    margin-bottom: 12px;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
    /*margin-left: 12px;*/
    /*margin-right: 12px;*/
}
.searchButton .image {
    width: 18px;
    height: 18px;
    margin-right: 11px;
}
.searchButton .text {
    font-family: "Nunito Sans", sans-serif;
    color: var(--dark-40);
    font-size: 16px;
    line-height: 24px;
}
.searchButton .inputSearch {
    flex: 1;
    background-color: var(--normal-80);
    font-family: "Nunito Sans", sans-serif;
    color: var(--dark-40);
    font-size: 16px;
    line-height: 24px;
    border: none;
}
.searchButton .inputSearch:focus {
    outline: none;
}

.searchButton .inputSearch::placeholder {
    font-family: "Nunito Sans", sans-serif;
    color: var(--dark-40);
    font-size: 16px;
    line-height: 24px;
}
.searchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding-right: 12px;*/
}
.searchContainer .searchButton {
    flex: 1;
    margin-bottom: 0;
}

.searchContainer .done {
    cursor: pointer;
    width: 50px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito Sans", sans-serif;
    color: var(--dark-40);
    font-size: 16px;
    line-height: 24px;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}

.notFoundContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
}
.notFoundContainer .title {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
}
.notFoundContainer .description {
    font-family: "Nunito Sans", sans-serif;
    color: var(--gray-70);
    font-size: 16px;
    line-height: 24px;
}


.listContainer {
    overflow: visible;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding-bottom: 25px;
}
.listData {
    flex: 1;
    overflow: visible;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 25px;
}

.genderContainer {
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}

@media screen and (max-width: 540px) {
    .searchContainer {
        padding-top: 12px;
    }
    .container {
        min-height: calc(100% - 75px);
    }
}
