.SpeedButton {
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 12px;
    background-color: var(--white);
    border-width: 1px;
    border-style: solid;
    border-color: var(--gray-90);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: background 0s;
    -webkit-tap-highlight-color: transparent;
}

.iconContainer {
    width: 108px;
    height: 94px;
}

@media screen and (max-width: 768px){
    .iconContainer {
        width: 86px;
        height: 74px;
    }
}

@media screen and (max-width: 540px){
    .iconContainer {
        width: 56px;
        height: 50px;
    }
}

.SpeedButton.active {
    background-color: var(--normal-80);
    border-color: var(--dark-20);
}
