.paymentElementTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;
}
.titleContainer {
    display: flex;
    align-items: center;
}
.paymentTitle {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-wrap: nowrap;
}
.paymentsIconsList {
    display: flex;
    align-items: center;
}
.driver {
    border-top: 1px solid #E6E7E6
}
.formContainer {
    padding-top: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}
.row {
    display: flex;
    gap: 32px;
    align-items: start;
}

.field {
    width: 100%;
    position: relative;
}

.text {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    padding-bottom: 12px;
}

.field .label {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--text);
    padding-left: 12px;
}


.field .input {
    background-color: var(--normal-80);
    border-radius: 12px;
    /*width: 100%;*/
    /*height: 48px;*/
    padding: 14px;
    align-items: center;
}

.field .input.error {
    background-color: var(--error-secondary);
    border: 1px solid var(--error-primary);
}

.field .input input {
    width: 100%;
}

.formImages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
    padding: 4px 12px;
}

.desc {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 4px 12px;
}

.formInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formInfo .text {
    text-align: center;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
}

.formContainer img {
    width: 100%;
    height: 40px;
}

.button {
    background-color: var(--dark-20);
    color: var(--white);
    border-radius: 12px;
    border: 1px solid var(--dark-20);
    padding: 12px;
    margin-bottom: 8px;
    /*margin-top: 12px;*/
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonText {
    padding-left: 8px;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
}

.checkContainer {
    cursor: pointer;
    display: flex;
    padding-bottom: 20px;
}

.checkBox {

}

.checkText {
    padding-left: 12px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

}

.button:disabled {
    border-color: var(--disableButtonBg);
    background-color: var(--disableButtonBg);
    color: var(--white);
}

.button:disabled svg path {
    stroke: #CBCCCB
}

.error.message {
    /*position: absolute;*/
    /*bottom: 0;*/
    /*left: 12px;*/
}

.error {
    color: var(--error-primary);
    padding-top: 5px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
}

@media screen and (max-width: 540px) {
    .row {
        gap: 20px;
    }

    .formContainer {
        /*padding: 20px;*/
    }

    .formImages {
        flex-wrap: wrap;
        gap: 0;
        justify-content: center;
    }

    .formImages > div {
        flex: 0 0 33.3333%;
        padding-bottom: 8px;
    }

    .formInfo .text {
        padding: 0 30px;
    }
}

.orText {
    text-align: center;
    padding-top: 20px;
    font-family: "Nunito Sans", sans-serif;
}

.infoText {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--gray-60);
}

