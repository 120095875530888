.bar {
    border-radius: 8px;
    background-color: var(--normal-80);
}
.progress {
    background-color: var(--dark-20);
    height: 8px;
    border-radius: 8px;
    transition: width 0.3s ease;
}
