.listContainer {
    overflow: visible;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4px 12px;
}
.description {
    text-align: center;
    color: var(--text);
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4px 12px 24px;
}

.text {
    color: var(--text);
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    padding-bottom: 12px;
}

.value {
    color: var(--dark-20);
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
}

@media screen and (max-width: 540px) {
    .container {
        height: auto;
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}
