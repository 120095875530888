.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;

    padding-bottom: 20px;
}
.optionsList {
    padding: 12px;
}
.sliderContainer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
}

.sliderLabels {
    display: flex;
    padding-top: 3px;
    justify-content: space-between;
}

.sliderLabels span {
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: var(--gray-60);
}
.sliderTitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    padding-bottom: 2px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--text);
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100% - 70px);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}
