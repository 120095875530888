.btnContainer {
    display: block;
    /*position: sticky;*/
    bottom: 0;
    z-index: 15;
    left: 0;
    width: 100%;
    /*background-color: var(--white);*/
    padding-bottom: 8px;
    padding-top: 12px;
    /*margin-left: 12px;*/
    /*margin-right: 12px;*/
    border: none;
    outline: none;
}
.button {

    background-color: var(--new-dark-20);
    color: var(--white);
    border-radius: 12px;
    border: 1px solid var(--new-dark-20);
    padding: 12px;

    cursor: pointer;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.withGradient:before {
    content: '';
    width: 100%;
    height: 91px;
    position: absolute;
    top: -21px;
    left: 0;
    z-index: -1;
    background: linear-gradient(0.13deg, #FFFFFF 57.21%, rgba(255, 255, 255, 0.6) 73.57%, rgba(255, 255, 255, 0.4) 85.14%, rgba(255, 255, 255, 0.02) 99.88%);
}
.button:disabled {
    /*opacity: 0.75;*/
    background-color: #a3d38c;
    border-color: #a3d38c;
}
