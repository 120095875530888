.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
}
.title {
    padding: 12px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: var(--text);
    text-align: center;
}
.images {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageContainer {
    width: 160px;
    text-align: center;
}
.image {
    width: 100%;
    height: auto;
}
.iconVS {
    padding-left: 20px;
    padding-right: 20px;
}
.imageFiltered {
    filter: grayscale(100%);
}
.imageText {
    padding-top: 4px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text);
}
.smilesContainer {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100% - 70px);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}
