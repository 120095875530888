.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}
.title {
    padding: 12px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: var(--text);
    text-align: center;
}
.containerEl {
    padding-top: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gradContainer {
    width: 360px;
    height: 230px;
    position: relative;
}
.gradOne {

}
.gradTwo {
    position: absolute;
    left: 50%;
    margin-left: -100px;
    bottom: 0;
}
.imagesContainer {
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -150px;
    transform: translateX(10px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.image {
    position: relative;
    width: 100px;
    height: auto;
}
.text {
    padding-bottom: 20px;
    padding-top: 8px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--text);
}
.infoContainer {
    padding: 4px 12px 8px;
}
.infoTitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: var(--text);
    padding: 4px 12px;
}
.infoDescription {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--text);
    padding: 4px 12px;
}

@media screen and (max-width: 540px) {
    .container {
        height: calc(100% - 70px);
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
    .text {
        padding-bottom: 48px;
    }
}


.fadeIn {
    opacity: 1;
    transition: all 1s ease;
    animation-name: fadeInOpacity;
    /*animation-iteration-count: 1;*/
    animation-timing-function: ease-in;
    animation-duration: 1s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
