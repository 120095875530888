.goalsContainer {
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
}
.goalsContainer .textInfoContainer {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 16px;
}
.goalsContainer .textInfoContainer .title {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.goalsContainer .textInfoContainer .description {
    font-family: "Nunito Sans", sans-serif;
    color: var(--text);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.goalsContainer .listContainer {
    padding-bottom: 24px;
    padding-left: 12px;
    padding-right: 12px;
}

.listContainer {
    overflow: auto;
}


.container {
    display: flex;
    flex-direction: column;
    flex: auto;
    height: auto;
    max-height: none;
    overflow: visible;
    justify-content: flex-start;
}
.containerData {
    overflow: visible;
    display: block;
    /*overflow: hidden;*/

}
.listData {
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
}
@media screen and (max-width: 540px) {
    .container {
        display: flex;
        flex-direction: column;
        flex: auto;
        height: auto;
        max-height: none;
        overflow: visible;
        justify-content: flex-start;
    }
    .containerData {
        overflow: visible;
        display: block;
    }
}
