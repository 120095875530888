.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
    padding-top: 4px;
}
.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 152px;
    border-radius: 12px;
    background-color: var(--new-normal-80);
    position: relative;
}

.button {
    width: 50%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    z-index: 5;
    transition: background-color 0.3s ease;
    -webkit-tap-highlight-color: transparent;
    color: var(--new-dark-20);
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

}
.button.active {
    background-color: var(--new-dark-20);
    color: var(--white);
}
