.productContainer {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--gray-90);
    margin-bottom: 24px;
    cursor: pointer;
}
.selected {
    background-color: var(--normal-80);
    border-color: var(--dark-20);
}
.productInfo {
    flex: 1;
    padding-left: 12px;
}
.name {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
}
.oldPrice {
    text-decoration: line-through;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--textGr);
}
.price {
    padding-left: 8px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--text);
}
.perDatInfo {
    display: flex;
    align-items: flex-start;
}
.perDayCurrency {
    padding-right: 5px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 22px;
    font-weight: 700;
    text-align: left;
}
.perDay {
    font-family: "Nunito Sans", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
}
.perDayText {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
    color: var(--textGr);
}
.mostPopular {
    position: absolute;
    top: -16px;
    right: 0;
    padding: 4px 16px 4px 16px;
    border-radius: 12px 12px 0 12px;
    background-color: var(--new-dark-20);
    color: var(--white);
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

}
.checkbox {
    position: relative;
    width: 24px;
    height: 24px;
}
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--white);
    border-radius: 50%;
    border: 2px solid var(--dark-20);
}
/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
    background-color: var(--normal-80);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    left: 2px;
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: var(--dark-20);
    border-radius: 50%;
}
