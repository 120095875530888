.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: calc(100% - 80px - 48px);*/
    overflow: visible;
}
.containerData {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: visible;

}
.listData {
    flex: 1;
    overflow: auto;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    padding-bottom: 20px;
}
.title {
    padding: 12px;
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: var(--text);
    text-align: center;
}

@media screen and (max-width: 540px) {
    .container {
        height: auto;
        flex: auto;
    }
    .containerData {
        flex: 1;
    }
}
