
.container {
    padding-top: 16px;
    padding-bottom: 8px;
}

.title {
    font-family: "Nunito Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    padding: 4px 12px 12px;
}
.subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    padding: 4px 12px 12px;
}

.paymentsListContainer {
    border: 1px solid var(--gray-90);
    border-radius: 12px;
}

.paymentElementContainer {
    border-bottom: 1px solid var(--gray-90);
}
.paymentElementContainer:last-child {
    border-bottom: none;
}
.paymentElementTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    height: 38px;
}
.titleContainer {
    display: flex;
    align-items: center;
}
.paymentTitle {
    padding-left: 12px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-wrap: nowrap;
}
.paymentsIconsList {
    display: flex;
    align-items: center;
}


.checkbox {
    position: relative;
    width: 24px;
    height: 24px;
}
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--white);
    border-radius: 50%;
    border: 2px solid var(--new-dark-20);
}
/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
    background-color: var(--normal-80);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    left: 2px;
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: var(--new-dark-20);
    border-radius: 50%;
}
